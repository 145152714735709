import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { boatEventItems } from '../constants';

@Component({
  selector: 'app-our-events',
  templateUrl: './our-events.component.html',
  styleUrls: ['./our-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OurEventsComponent {
  events = boatEventItems;
  config: SwiperConfigInterface = {};
  isExpanded = false;

  longText = `
    Whether you’re in Sydney city for the first time, or looking for a day to remember with your friends and family, Sydney Harbour Escapes offer you a great way to enjoy Sydney from the water, with luxury boat hire for private parties, corporate events or private charter.
  `;
  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        600: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
      },
    };

    this.changeDetectorRef.markForCheck();
  }

  onExpandContent() {
    this.isExpanded = !this.isExpanded;
  }
}
