export const boatFeatures = [
  'Catering',
  'Licenced Bar',
  'BYO Food',
  'BYO Alcohol',
  'BBQ',
  'Swimming',
  'TV DVD',
  'Air Conditioning',
  'Ipod',
  'PA/Microphone',
  'WiFi',
  'Offshore Charter',
] as const;

export type BoatFeature = (typeof boatFeatures)[number];
