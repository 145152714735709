export const boatEventItems = [
  {
    id: 1,
    name: 'Birthday Parties',
    value: 'birthday',
    image: 'assets/images/events/bday-parties.jpg',
  },
  {
    id: 2,
    name: 'Corporate Events',
    value: 'corporate',
    image: 'assets/images/events/corporate.jpg',
  },
  {
    id: 3,
    name: 'Weddings',
    value: 'weddings',
    image: 'assets/images/events/wedding.jpg',
  },
  {
    id: 4,
    name: 'Private Party',
    value: 'private-party',
    image: 'assets/images/events/private-party.jpg',
  },
];
