import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BoatsService } from '../services/boats.service';
import { BoatsActions } from './boats.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { BoatsRepositoryService } from '../services/boats-repository.service';

@Injectable()
export class BoatsEffects {
  loadBoats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatsActions.loadBoats),
      switchMap(() =>
        this.boatsRepositoryService.fetchBoats().pipe(
          map(({ data }) =>
            BoatsActions.boatsLoadComplete(
              data?.list.map(item => ({
                ...item,
                boatAlbumPics:
                  typeof item?.boatAlbumPics === 'string' ? JSON.parse(item?.boatAlbumPics) : item?.boatAlbumPics,
              }))
            )
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    readonly actions$: Actions,
    readonly boatsService: BoatsService,
    readonly boatsRepositoryService: BoatsRepositoryService
  ) {}
}
