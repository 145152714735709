import { Component, OnInit, Input, ChangeDetectionStrategy, AfterViewInit, OnDestroy, NgZone } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Settings, AppSettings } from '../../app.settings';
import { AppService } from '../../app.service';
import { FormGroup } from '@angular/forms';
import { boatCapacityItems } from '../constants/boat-capacity-items';

@Component({
  selector: 'app-bottom-banner',
  templateUrl: './bottom-banner.component.html',
  styleUrls: ['./bottom-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomBannerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() variant = 1;
  @Input() vertical = false;
  @Input('backgroundImage') backgroundImage: string;
  @Input('bgImageAnimate') bgImageAnimate: string;
  @Input('contentOffsetToTop') contentOffsetToTop: boolean;
  @Input('contentMinHeight') contentMinHeight: number;
  @Input('title') title: string;
  @Input('desc') desc: string;
  @Input('isHomePage') isHomePage = false;
  @Input('fullscreen') fullscreen = false;

  form: FormGroup;
  propertyTypes = [];
  bgImage: SafeStyle;
  settings: Settings;

  constructor(
    private readonly appSettings: AppSettings,
    private readonly sanitizer: DomSanitizer,
    private readonly appService: AppService,
    private readonly ngZone: NgZone
  ) {
    this.settings = this.appSettings.settings;
    this.settings.headerBgImage = true;
  }

  ngOnInit() {
    if (this.backgroundImage) {
      this.bgImage = this.sanitizer.bypassSecurityTrustStyle(`url(${this.backgroundImage})`);
    }
    this.propertyTypes = boatCapacityItems;
  }

  ngAfterViewInit() {
    if (this.contentOffsetToTop) {
      this.ngZone.run(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
      });
    }
  }

  ngOnDestroy() {
    this.ngZone.run(() => {
      this.settings.headerBgImage = false;
      this.settings.contentOffsetToTop = false;
    });
  }

  onEnquire() {
    console.log('enquire');
  }

  getAppearance() {
    return this.variant !== 3 ? 'outline' : '';
  }

  getFloatLabel() {
    return this.variant === 1 ? 'always' : '';
  }
}
