import { createAction } from '@ngrx/store';
import { Boat } from 'src/app/shared/models';

export namespace BoatsActions {
  const BOAT_LOAD_DATA = '[Boats] Load data';
  const BOAT_LOAD_DATA_COMPLETE = '[Boats] Load data complete';
  const BOAT_LOAD_DATA_FAIL = '[Boats] Load data fail';

  export const loadBoats = createAction(BOAT_LOAD_DATA);

  export const boatsLoadComplete = createAction(BOAT_LOAD_DATA_COMPLETE, (boats: Boat[]) => ({ boats }));

  export const boatsLoadFail = createAction(BOAT_LOAD_DATA_FAIL);
}
