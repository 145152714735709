import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeComponent {
  constructor(private readonly formBuilder: FormBuilder) {}

  subscribeForm = this.formBuilder.group({
    name: ['', Validators.required],
    emailAddress: ['', Validators.required],
  });
}
