import { Component, Input, ViewChild, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Settings, AppSettings } from '../../app.settings';
import { Boat } from '../models';
import { animate, style, transition, trigger } from '@angular/animations';
import { getImgUrl } from 'src/app/theme/utils/image-getter';

@Component({
  selector: 'app-boat-item-card',
  templateUrl: './boat-item-card.component.html',
  styleUrls: ['./boat-item-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [transition(':enter', [style({ opacity: 0 }), animate('0.5s ease-in', style({ opacity: 1 }))])]),
  ],
})
export class BoatItemCardComponent {
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;

  @Input() boat: Boat;
  @Input() viewType: string = 'grid';
  @Input() viewColChanged: boolean = false;
  @Input() fullWidthPage: boolean = true;

  column = 4;
  settings: Settings;

  config: SwiperConfigInterface = {
    slidesPerView: 1,
    spaceBetween: 10,
    keyboard: false,
    navigation: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'progressbar',
    },
    grabCursor: true,
    loop: true,
    preloadImages: false,
    lazy: true,
    nested: true,
    speed: 500,
    effect: 'slide',
  };

  getSizedImgUrl(boatImage: string, size: string): string {
    return getImgUrl(boatImage, size);
  }

  constructor(private readonly appSettings: AppSettings) {
    this.settings = this.appSettings.settings;
  }

  ngOnChanges(changes: SimpleChanges) {
    const viewColChange = changes['viewColChanged'];

    if (viewColChange) {
      this.getColumnCount(viewColChange.currentValue);

      if (!viewColChange.isFirstChange() && this.boat?.gallery?.length > 1) {
        this.directiveRef.update();
      }
    }
  }

  toggleFavorite(boat: Boat): void {
    const favorites = this.getFavorites();
    const boatIndex = favorites.indexOf(boat.boatName);

    if (boatIndex > -1) {
      // Remove boat from favorites if it's already favorited
      favorites.splice(boatIndex, 1);
    } else {
      // Add boat to favorites
      favorites.push(boat.boatName);
    }

    // Save updated favorites back to localStorage
    localStorage.setItem('favoriteBoats', JSON.stringify(favorites));
  }

  getFavorites(): string[] {
    // Retrieve favorites from localStorage, or initialize as an empty array
    const favorites = localStorage.getItem('favoriteBoats');
    return favorites ? JSON.parse(favorites) : [];
  }

  isFavorite(boat: Boat): boolean {
    // Check if the boat's ID exists in the list of favorites
    return this.getFavorites().includes(boat.boatName);
  }

  getColumnCount(value: number) {
    const columnMap: { [key: number]: number } = {
      25: 4,
      33.3: 3,
      50: 2,
    };

    this.column = columnMap[value] ?? 1;
  }
}
