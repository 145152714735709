export const boatEventTypes = [
  'Boat Hire',
  'Birthday Parties',
  'Corporate Events',
  'Weddings',
  'New Years Eve - Private Hire',
  'Team Building',
  'Bucks Party',
  'Hens Party',
  'Overnight Boat Stays',
] as const;

export type BoatEventType = (typeof boatEventTypes)[number];
