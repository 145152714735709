import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';
import { boatHolidayItems } from '../constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-our-holiday',
  templateUrl: './our-holiday.component.html',
  styleUrls: ['./our-holiday.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OurHolidayComponent {
  holidays = boatHolidayItems;
  config: SwiperConfigInterface = {};
  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router
  ) {}

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        600: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
      },
    };

    this.changeDetectorRef.markForCheck();
  }

  onNavigateToHolidays() {
    this.router.navigate(['/holidays']);
  }
}
