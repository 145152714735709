import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-properties-search-results-filters',
  templateUrl: './properties-search-results-filters.component.html',
  styleUrls: ['./properties-search-results-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertiesSearchResultsFiltersComponent {
  @Input() searchFields: any;
  @Output() onRemoveSearchField: EventEmitter<any> = new EventEmitter<any>();

  remove(field: string) {
    this.onRemoveSearchField.emit(field);
  }
}
