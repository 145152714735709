import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Boat } from '../models';

@Component({
  selector: 'app-boats-carousel',
  templateUrl: './boats-carousel.component.html',
  styleUrls: ['./boats-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoatsCarouselComponent {
  @Input() boats: Boat[] = [];

  @Output() readonly viewThisBoat = new EventEmitter<Boat>();
  @Output() readonly getInstantQuote = new EventEmitter<Boat>();

  config: SwiperConfigInterface = {
    observer: true,
    slidesPerView: 4,
    spaceBetween: 10,
    keyboard: true,
    navigation: { nextEl: '.prop-next', prevEl: '.prop-prev' },
    pagination: true,
    grabCursor: true,
    loop: false,
    preloadImages: true,
    lazy: false,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 5,
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 8,
      },
      960: {
        slidesPerView: 3,
        spaceBetween: 8,
      },
      1280: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
    },
  };
}
