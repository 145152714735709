import { Menu } from '../menu.model';

export const horizontalMenuItems: Menu[] = [
  {
    id: 5,
    title: 'Boat Hire',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: true,
    parentId: 0,
    queryParams: null,
  },
  {
    id: 21,
    title: 'Explore All Boats',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 5,
    queryParams: null,
  },
  {
    id: 22,
    title: 'Power Boats',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 5,
    queryParams: {
      type: 'boatHire',
      value: 'Power Boats',
    },
  },
  {
    id: 23,
    title: 'Catamarans',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 5,
    queryParams: {
      type: 'boatHire',
      value: 'Catamarans',
    },
  },
  {
    id: 24,
    title: 'Large Function Vessels',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 5,
    queryParams: {
      type: 'boatHire',
      value: 'Large Function Vessels',
    },
  },
  {
    id: 25,
    title: 'Sailing Boats',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 5,
    queryParams: {
      type: 'boatHire',
      value: 'Sailing Boats',
    },
  },
  {
    id: 26,
    title: 'Superyachts',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 5,
    queryParams: {
      type: 'boatHire',
      value: 'Superyachts',
    },
  },
  {
    id: 3,
    title: 'Events',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: true,
    parentId: 0,
    queryParams: null,
  },
  {
    id: 31,
    title: 'Explore All Events',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: null,
  },
  {
    id: 32,
    title: 'Birthday Parties',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'birthday',
    },
  },
  {
    id: 33,
    title: 'Corporate Events',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'corporate',
    },
  },
  {
    id: 34,
    title: 'Weddings',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'weddings',
    },
  },
  {
    id: 35,
    title: 'Vivid Sydney',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'vivid',
    },
  },
  {
    id: 36,
    title: 'Australia Day',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'australia day',
    },
  },
  {
    id: 37,
    title: 'New Years Day',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'new years day',
    },
  },
  {
    id: 38,
    title: 'NYE - Private Hire',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'private hire',
    },
  },
  {
    id: 39,
    title: 'NYE - Cruise Tickets',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'cruise tickets',
    },
  },
  {
    id: 25,
    title: 'Luxury Yatch',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 0,
    queryParams: {
      type: 'boatHire',
      value: 'Superyachts',
    },
  },
  {
    id: 32,
    title: 'Corporate Events',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 0,
    queryParams: {
      type: 'events',
      value: 'corporate',
    },
  },
  {
    id: 45,
    title: 'How To Hire',
    routerLink: '/how',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 0,
    queryParams: null,
  },
  {
    id: 70,
    title: 'About',
    routerLink: '/about',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 0,
    queryParams: null,
  },
  {
    id: 60,
    title: 'Contact',
    routerLink: '/contact',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 0,
    queryParams: null,
  },
];

export const verticalMenuItems: Menu[] = [
  {
    id: 1,
    title: 'NAV.HOME',
    routerLink: '/',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 0,
    queryParams: null,
  },
  {
    id: 2,
    title: 'NAV.PROPERTIES',
    routerLink: '/properties',
    href: null,
    target: null,
    hasSubMenu: true,
    parentId: 0,
    queryParams: null,
  },
  {
    id: 21,
    title: 'Explore All Boats',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 2,
    queryParams: null,
  },
  {
    id: 22,
    title: 'Power Boats',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 2,
    queryParams: {
      type: 'boatHire',
      value: 'Power Boats',
    },
  },
  {
    id: 23,
    title: 'Catamarans',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 2,
    queryParams: {
      type: 'boatHire',
      value: 'Catamarans',
    },
  },
  {
    id: 24,
    title: 'Large Function Vessels',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 2,
    queryParams: {
      type: 'boatHire',
      value: 'Large Function Vessels',
    },
  },
  {
    id: 25,
    title: 'Sailing Boats',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 2,
    queryParams: {
      type: 'boatHire',
      value: 'Sailing Boats',
    },
  },
  {
    id: 26,
    title: 'Superyachts',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 2,
    queryParams: {
      type: 'boatHire',
      value: 'Superyachts',
    },
  },
  {
    id: 3,
    title: 'Events',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: true,
    parentId: 0,
    queryParams: null,
  },
  {
    id: 31,
    title: 'Birthday Parties',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'birthday',
    },
  },
  {
    id: 32,
    title: 'Corporate Events',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'corporate',
    },
  },
  {
    id: 33,
    title: 'Weddings',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'weddings',
    },
  },
  {
    id: 34,
    title: 'Vivid Sydney',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'vivid',
    },
  },
  {
    id: 35,
    title: 'Australia Day',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'australia day',
    },
  },
  {
    id: 36,
    title: 'New Years Day',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'new years day',
    },
  },
  {
    id: 37,
    title: 'NYE - Private Hire',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'private hire',
    },
  },
  {
    id: 38,
    title: 'NYE - Cruise Tickets',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 3,
    queryParams: {
      type: 'events',
      value: 'cruise tickets',
    },
  },
  {
    id: 4,
    title: 'Activites',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: true,
    parentId: 0,
    queryParams: null,
  },
  {
    id: 41,
    title: 'Overnight Boat Stays',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 4,
    queryParams: {
      type: 'activities',
      value: 'overnight',
    },
  },
  {
    id: 42,
    title: 'Water Transfers',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 4,
    queryParams: {
      type: 'activities',
      value: 'water',
    },
  },
  {
    id: 43,
    title: 'Team Building',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 4,
    queryParams: {
      type: 'activities',
      value: 'team',
    },
  },
  {
    id: 44,
    title: 'Meeting and Conference Rooms',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 4,
    queryParams: {
      type: 'activities',
      value: 'meeting',
    },
  },
  {
    id: 45,
    title: 'Private Fishing Charters',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 4,
    queryParams: {
      type: 'activities',
      value: 'fishing',
    },
  },
  {
    id: 5,
    title: 'All Boats',
    routerLink: '/boats',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 0,
    queryParams: null,
  },
  {
    id: 60,
    title: 'NAV.CONTACT',
    routerLink: '/contact',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 0,
    queryParams: null,
  },
  {
    id: 70,
    title: 'About',
    routerLink: '/about',
    href: null,
    target: null,
    hasSubMenu: false,
    parentId: 0,
    queryParams: null,
  },
];
