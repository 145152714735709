import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BOATS_FEATURE_KEY, BoatsState } from './boats.reducer';

export namespace BoatsSelectors {
  const boatsState = createFeatureSelector<BoatsState>(BOATS_FEATURE_KEY);

  export const isLoading = createSelector(boatsState, state => state.isLoading);

  export const isErrored = createSelector(boatsState, state => state.isErrored);

  export const boats = createSelector(boatsState, state => state.boats);
}
