export * from './boat-hire-rate.model';
export * from './boat-staff-fee.model';
export * from './boat-event-type.model';
export * from './boat-owner.model';
export * from './boat.model';
export * from './boat-features.model';
export * from './boat-special-package.model';
export * from './boat-byo-fee-category.model';
export * from './boat-food-fee.model';
export * from './boat-additional-hour-fee.model';
export * from './boat-wharf-fee.model';
export * from './boat-others-fee.model';
export * from './boat-byo-fee.model';
export * from './boat-drink-package-fee.model';
export * from './boat-faq.model';
export * from './boat-menus.model';
export * from './boat-response.model';
export * from './boat-list-model';
export * from './boat-list-response.model';
export * from './boat-types.model';
export * from './loading-state.model';
