import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppService } from '../../app.service';
import { ActivatedRoute } from '@angular/router';
import { boatCapacityItems } from '../constants/boat-capacity-items';
import { boatTypeItems } from '../constants';
@Component({
  selector: 'app-properties-search',
  templateUrl: './properties-search.component.html',
  styleUrls: ['./properties-search.component.scss'],
})
export class PropertiesSearchComponent implements OnInit {
  @Input() variant: number = 1;
  @Input() vertical: boolean = false;
  @Input() searchOnBtnClick: boolean = false;
  @Input() removedSearchField: string;
  @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();
  public showMore: boolean = false;
  public form: FormGroup;
  public propertyTypes = [];
  public propertyStatuses = [];
  public cities = [];
  public neighborhoods = [];
  public streets = [];
  public features = [];
  public propertyEvents = [];
  public activities = [];

  public category: any = [];
  public boatHire: any = null;
  public activity: any = [];
  public guests: any = null;

  constructor(
    public appService: AppService,
    public fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.category = [];
      this.boatHire = null;
      this.activity = [];
      if (undefined !== params['type']) {
        if (params['type'] === 'boatHire') {
          this.boatHire = params['value'];
        }
        if (params['type'] === 'events') {
          this.category.push(params['value']);
        }
        if (params['type'] === 'activities') {
          this.activity.push(params['value']);
        }
        if (params['type'] === 'eventguests') {
          this.category.push(params['event']);
          this.guests = params['guests'];
        }
      }
      console.log(this.category);
      console.log(this.guests);
    });

    if (this.vertical) {
      this.showMore = true;
    }
    this.propertyTypes = boatCapacityItems;
    this.propertyStatuses = boatTypeItems;
    this.cities = this.appService.getCities();
    this.neighborhoods = this.appService.getNeighborhoods();
    this.streets = this.appService.getStreets();
    this.features = this.appService.getFeatures();
    this.propertyEvents = this.appService.getEvents();
    this.activities = this.appService.getActivities();
    this.form = this.fb.group({
      propertyType: null,
      propertyEvent: null,
      propertyStatus: null,
      price: this.fb.group({
        from: null,
        to: null,
      }),
      city: null,
      zipCode: null,
      neighborhood: null,
      street: null,
      bedrooms: this.fb.group({
        from: null,
        to: null,
      }),
      bathrooms: this.fb.group({
        from: null,
        to: null,
      }),
      garages: this.fb.group({
        from: null,
        to: null,
      }),
      area: this.fb.group({
        from: null,
        to: null,
      }),
      yearBuilt: this.fb.group({
        from: null,
        to: null,
      }),
      features: this.buildFeatures(),
      events: this.buildEvents(),
      activities: this.buildActivities(),
    });

    this.onSearchChange.emit(this.form);

    // const dOption = this.propertyTypes.find(op => op.name === this.guests);
    // this.form.get('propertyType').setValue(dOption);
  }

  public buildFeatures() {
    const arr = this.features.map(feature => {
      return this.fb.group({
        id: feature.id,
        name: feature.name,
        selected: feature.selected,
      });
    });
    return this.fb.array(arr);
  }

  public buildEvents() {
    const arr = this.propertyEvents.map(event => {
      return this.fb.group({
        id: event.id,
        name: event.name,
        selected: event.selected,
      });
    });
    return this.fb.array(arr);
  }

  public buildActivities() {
    const arr = this.activities.map(activity => {
      return this.fb.group({
        id: activity.id,
        name: activity.name,
        selected: activity.selected,
      });
    });
    return this.fb.array(arr);
  }

  ngOnChanges() {
    if (this.removedSearchField) {
      if (this.removedSearchField.indexOf('.') > -1) {
        let arr = this.removedSearchField.split('.');
        this.form.controls[arr[0]]['controls'][arr[1]].reset();
      } else if (this.removedSearchField.indexOf(',') > -1) {
        let arr = this.removedSearchField.split(',');
        this.form.controls[arr[0]]['controls'][arr[1]]['controls']['selected'].setValue(false);
      } else {
        this.form.controls[this.removedSearchField].reset();
      }
    }
  }

  public reset() {
    this.form.reset({
      propertyType: null,
      propertyEvent: null,
      propertyStatus: null,
      price: {
        from: null,
        to: null,
      },
      city: null,
      zipCode: null,
      neighborhood: null,
      street: null,
      bedrooms: {
        from: null,
        to: null,
      },
      bathrooms: {
        from: null,
        to: null,
      },
      garages: {
        from: null,
        to: null,
      },
      area: {
        from: null,
        to: null,
      },
      yearBuilt: {
        from: null,
        to: null,
      },
      features: this.features,
      events: this.propertyEvents,
      activities: this.activities,
    });
  }

  public search() {
    this.onSearchClick.emit();
  }

  public onSelectCity() {
    this.form.controls['neighborhood'].setValue(null, { emitEvent: false });
    this.form.controls['street'].setValue(null, { emitEvent: false });
  }
  public onSelectNeighborhood() {
    this.form.controls['street'].setValue(null, { emitEvent: false });
  }

  public getAppearance() {
    return this.variant != 3 ? 'outline' : '';
  }
  public getFloatLabel() {
    return this.variant == 1 ? 'always' : '';
  }
}
