import { Injectable, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { DOCUMENT } from '@angular/common';

import { Menu } from './menu.model';
import { verticalMenuItems, horizontalMenuItems } from './helpers/constants';

@Injectable()
export class MenuService {
  constructor(
    private readonly location: Location,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  getVerticalMenuItems(): Menu[] {
    return verticalMenuItems;
  }

  getHorizontalMenuItems(): Menu[] {
    return horizontalMenuItems;
  }

  expandActiveSubMenu(menu: Menu[]): void {
    const routerLink = decodeURIComponent(this.location.path());
    let menuItem = menu.find(item => item.routerLink === routerLink);

    while (menuItem && menuItem.parentId !== 0) {
      menuItem = menu.find(item => item.id === menuItem.parentId);
      if (menuItem) {
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  toggleMenuItem(menuId: number): void {
    const menuItem = this.document.getElementById(`menu-item-${menuId}`);
    const subMenu = this.document.getElementById(`sub-menu-${menuId}`);

    if (subMenu && menuItem) {
      const isSubMenuShown = subMenu.classList.contains('show');
      subMenu.classList.toggle('show', !isSubMenuShown);
      menuItem.classList.toggle('expanded', !isSubMenuShown);
    }
  }

  closeOtherSubMenus(menu: Menu[], menuId: number): void {
    const currentMenuItem = menu.find(item => item.id === menuId);

    if (!currentMenuItem) return;

    menu.forEach(item => {
      const isSiblingOrTopLevelMenu =
        (item.id !== menuId && item.parentId === currentMenuItem.parentId) ||
        (currentMenuItem.parentId === 0 && item.id !== menuId);

      if (isSiblingOrTopLevelMenu) {
        this.closeSubMenu(item.id);
      }
    });
  }

  closeAllSubMenus(): void {
    verticalMenuItems.forEach(item => this.closeSubMenu(item.id));
  }

  private closeSubMenu(menuId: number): void {
    const subMenu = this.document.getElementById(`sub-menu-${menuId}`);
    const menuItem = this.document.getElementById(`menu-item-${menuId}`);

    if (subMenu && subMenu.classList.contains('show')) {
      subMenu.classList.remove('show');
      if (menuItem) {
        menuItem.classList.remove('expanded');
      }
    }
  }
}
