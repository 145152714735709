import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-meet-our-team',
  templateUrl: './meet-our-team.component.html',
  styleUrls: ['./meet-our-team.component.scss'],
})
export class MeetOurTeamComponent implements OnInit {
  public testimonials;
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
  };

  constructor(public appService: AppService) {}

  ngOnInit() {
    this.testimonials = this.appService.getOurTeam();
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      // breakpoints: {
      //   480: {
      //     slidesPerView: 1
      //   },
      //   740: {
      //     slidesPerView: 2,
      //   },
      //   960: {
      //     slidesPerView: 3,
      //   }
      // }
    };
  }
}
