import { Action, createReducer, on } from '@ngrx/store';
import { Boat, LoadingState, loadingStateDefault } from 'src/app/shared/models';
import { BoatsActions } from './boats.actions';

export const BOATS_FEATURE_KEY = 'boats';

export interface BoatsState extends LoadingState {
  readonly boats: Boat[];
}

export interface BoatsPartialState {
  readonly [BOATS_FEATURE_KEY]: BoatsState;
}

const initialState: BoatsState = {
  ...loadingStateDefault,
  boats: [],
};

const boatsReducer = createReducer(
  initialState,
  on(BoatsActions.boatsLoadFail, state => ({
    ...state,
    isLoading: false,
    boats: [],
    isErrored: true,
  })),
  on(BoatsActions.boatsLoadComplete, (state, { boats }) => ({
    ...state,
    isLoading: false,
    isErrored: false,
    boats,
  })),
  on(BoatsActions.resetState, state => ({
    ...state,
    isLoading: false,
    isErrored: false,
    boats: [],
  }))
);

export function reducer(state: BoatsState, action: Action) {
  return boatsReducer(state, action);
}
