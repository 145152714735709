import { Component, OnInit, Input, ChangeDetectionStrategy, AfterViewInit, OnDestroy, NgZone } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Settings, AppSettings } from '../../app.settings';
import { AppService } from '../../app.service';
import { FormGroup } from '@angular/forms';
import { boatCapacityItems } from '../constants/boat-capacity-items';

@Component({
  selector: 'app-header-about',
  templateUrl: './header-about.component.html',
  styleUrls: ['./header-about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderAboutComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() variant = 1;
  @Input() vertical = false;
  @Input() backgroundImage: string;
  @Input() bgImageAnimate: string;
  @Input() contentOffsetToTop: boolean;
  @Input() contentMinHeight: number;
  @Input() title: string;
  @Input() subHeader: string;
  @Input() desc: string;
  @Input() isHomePage = false;
  @Input() fullscreen = false;

  form: FormGroup;
  propertyTypes = [];
  bgImage: SafeStyle;
  settings: Settings;

  constructor(
    private readonly appSettings: AppSettings,
    private readonly sanitizer: DomSanitizer,
    private readonly appService: AppService,
    private readonly ngZone: NgZone
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    if (this.backgroundImage) {
      this.bgImage = this.sanitizer.bypassSecurityTrustStyle(`url(${this.backgroundImage})`);
    }
    this.propertyTypes = boatCapacityItems;
  }

  ngAfterViewInit() {
    if (this.contentOffsetToTop) {
      this.ngZone.run(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
      });
    }

    this.ngZone.run(() => {
      this.settings.headerBgImage = true;
    });
  }

  ngOnDestroy() {
    this.ngZone.run(() => {
      this.settings.headerBgImage = false;
      this.settings.contentOffsetToTop = false;
    });
  }

  getAppearance() {
    return this.variant !== 3 ? 'outline' : '';
  }

  getFloatLabel() {
    return this.variant === 1 ? 'always' : '';
  }
}
