import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(
    private readonly titleService: Title,
    private readonly meta: Meta
  ) {}

  updateMetaInfo(content: string) {
    if (content) {
      this.meta.updateTag({ name: 'description', content });
    }
  }

  updateMetaKeywords(content: string) {
    if (content) {
      this.meta.updateTag({ name: 'keywords', content });
    }
  }

  updateTitle(title?: string): void {
    if (title) {
      this.titleService.setTitle(title);
    }
  }
}
