import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-settings-menu-toolbar',
  templateUrl: './settings-menu-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsMenuToolbarComponent {
  @Output() sideNavClick = new EventEmitter<void>();

  favoritesCount: number;
  isFavoritesHidden: boolean;

  constructor(private readonly appService: AppService) {
    this.favoritesCount = this.appService.Data.favorites.length;
    this.isFavoritesHidden = this.favoritesCount === 0;
  }
}
