import { Component, OnInit, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  providers: [MenuService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalMenuComponent implements OnInit {
  @Input() menuParentId: number;
  @Output() readonly menuClick = new EventEmitter<void>();

  menuItems: Menu[];

  constructor(private readonly menuService: MenuService) {}

  ngOnInit() {
    this.menuItems = this.menuService.getVerticalMenuItems().filter(item => item.parentId === this.menuParentId);
  }

  onClick(menuId: number, hasSubMenu: boolean) {
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);

    if (hasSubMenu === false) {
      this.menuClick.emit();
    } else {
      return;
    }
  }
}
