import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(
    private readonly titleService: Title,
    private readonly meta: Meta,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  updateMetaInfo(content) {
    this.meta.updateTag({ name: 'description', content });
  }

  updateTitle(title?: string): void {
    if (title) {
      this.titleService.setTitle(title);
      return;
    }

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.getPrimaryRoute(this.activatedRoute)),
        filter(route => route && route.outlet === 'primary'),
        mergeMap(route => route.data),
        filter(data => Boolean(data['title']))
      )
      .subscribe(data => {
        this.titleService.setTitle(data['title']);
      });
  }

  private getPrimaryRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
