import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Boat, BoatFeature } from 'src/app/shared/models';
import { BoatsSearchFilterRequest } from '../models';

@Injectable()
export class BoatsService {
  filterBoats(boats: Boat[], filters: BoatsSearchFilterRequest): Boat[] {
    // Apply the filter logic and collect matching boats
    const filteredBoats = boats.filter(boat => {
      // Filter by BYO
      const matchesByo =
        !filters.byo.length ||
        (boat.boatFeatures && filters.byo.every(byo => boat.boatFeatures.includes(byo as BoatFeature)));

      // Filter by Event
      const matchesEvent = !filters.event || (boat.boatActivities && boat.boatActivities.includes(filters.event));

      // Filter by maxCapacity
      const matchesMaxCapacity =
        !filters.maxCapacity.length ||
        filters.maxCapacity.some(
          capacity =>
            boat.maxGuest >= capacity.minGuest && (capacity.maxGuest === null || boat.maxGuest <= capacity.maxGuest)
        );

      // Filter by Price
      const matchesPrice =
        (!filters.price.from || boat.priceFrom >= filters.price.from) &&
        (!filters.price.to || boat.priceFrom <= filters.price.to);

      // Filter by Type
      const matchesType = !filters.type.length || (boat.boatType && filters.type.includes(boat.boatType));

      // Only include the boat if all filters match
      return matchesByo && matchesEvent && matchesMaxCapacity && matchesPrice && matchesType;
    });

    // Return the filtered boats, or an empty array if no matches found
    return filteredBoats.length > 0 ? filteredBoats : [];
  }

  sortBoats(criteria: string, boats: Boat[]): Boat[] {
    switch (criteria) {
      case 'popular':
        return boats.sort((a, b) => b.visits - a.visits);
      case 'high':
        return boats.sort((a, b) => b.priceFrom - a.priceFrom);
      case 'low':
        return boats.sort((a, b) => a.priceFrom - b.priceFrom);
      case 'A':
        return boats.sort((a, b) => a.boatName.localeCompare(b.boatName));
      case 'Z':
        return boats.sort((a, b) => b.boatName.localeCompare(a.boatName));
      case 'large':
        return boats.sort((a, b) => b.maxGuest - a.maxGuest);
      case 'small':
        return boats.sort((a, b) => a.maxGuest - b.maxGuest);
      default:
        return boats;
    }
  }

  prepareFilterRequest(filterParams: FormGroup): BoatsSearchFilterRequest {
    // Use `reduce` to transform the form group values into the final filter request
    return Object.entries(filterParams).reduce(
      (acc, [key, value]) => {
        // Check if the value is an array (e.g., byo, type, maxCapacity)
        if (Array.isArray(value)) {
          switch (key) {
            case 'maxCapacity':
              // For maxCapacity, filter items with selected true and map them to guest range objects
              acc[key] = value.filter(item => item.selected).map(item => this.getGuestRange(item.name));
              break;
            default:
              // For other array types (byo, type), filter selected items and map them to their names
              acc[key] = value.filter(({ selected }) => selected).map(({ name }) => name);
          }
        } else if (key === 'event' && typeof value === 'object' && value?.name) {
          // If the key is 'event' and the value is an object, extract the event name
          acc[key] = value.name;
        } else {
          // For other non-array values, retain them as they are (e.g., generalSearch, price)
          acc[key] = value;
        }
        return acc;
      },
      // Initialize accumulator with default values for the filter request object
      {
        byo: [],
        event: '',
        maxCapacity: [],
        price: { from: null, to: null },
        type: [],
      }
    );
  }

  private getGuestRange(name: string | undefined):
    | {
        minGuest: number;
        maxGuest: number | null;
      }
    | undefined {
    const guestRanges: Record<string, { minGuest: number; maxGuest: number | null }> = {
      'Up to 35 Guests': { minGuest: 0, maxGuest: 35 },
      '36-70 Guests': { minGuest: 36, maxGuest: 70 },
      'More than 71 Guests': { minGuest: 71, maxGuest: null },
    };

    return guestRanges[name || ''];
  }
}
