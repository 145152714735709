import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Settings, AppSettings } from '../../app.settings';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { boatCapacityItems } from '../constants/boat-capacity-items';
import { boatEventItems } from '../constants';

@Component({
  selector: 'app-header-image',
  templateUrl: './header-image.component.html',
  styleUrls: ['./header-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderImageComponent implements OnInit, OnDestroy {
  @Input() variant = 1;
  @Input() vertical = false;
  @Input() backgroundImage: string;
  @Input() bgImageAnimate: boolean;
  @Input() contentOffsetToTop: boolean;
  @Input() contentMinHeight;
  @Input() title;
  @Input() desc: string;
  @Input() isHomePage = false;
  @Input() fullscreen = false;

  form: FormGroup;
  guests = boatCapacityItems;
  events = boatEventItems;
  bgImage;
  settings: Settings;

  constructor(
    private readonly appSettings: AppSettings,
    private readonly sanitizer: DomSanitizer,
    private readonly fb: FormBuilder,
    private readonly router: Router
  ) {
    this.settings = this.appSettings.settings;
    this.settings.headerBgImage = true;
  }

  ngOnInit() {
    this.form = this.fb.group({
      event: [null],
      maxCapacity: [null],
    });

    if (this.contentOffsetToTop) {
      this.settings.contentOffsetToTop = this.contentOffsetToTop;
    }

    if (this.backgroundImage) {
      this.bgImage = this.sanitizer.bypassSecurityTrustStyle('url(' + this.backgroundImage + ')');
    }
  }

  ngOnDestroy() {
    this.settings.headerBgImage = false;
    this.settings.contentOffsetToTop = false;
  }

  getAppearance() {
    return this.variant !== 3 ? 'outline' : '';
  }

  getFloatLabel() {
    return this.variant === 1 ? 'always' : '';
  }

  searchBoats() {
    this.router.navigate(['/boats'], {
      queryParams: {
        event: this.form.value.event,
        maxCapacity: this.form.value.maxCapacity,
      },
    });
  }
}
