import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-refundable-booking',
  templateUrl: './refundable-booking.component.html',
  styleUrls: ['./refundable-booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefundableBookingComponent {
  showFullContent = false;
  showRefundableContent = true;
  fullContent: SafeHtml;
  truncatedContent: SafeHtml;

  constructor(private readonly sanitizer: DomSanitizer) {
    const fullText = `
      We provide all our customers with Refundable Terms, so if you are unable to attend your booking due to unforeseen circumstances outside of your control, you may be entitled to a refund - please see our full set of Refundable Terms and Conditions <a href="https://refundable.me/extended/en" target="_blank" style="outline: none !important; transition: 0.2s; color: #2e305b !important; font-weight: 700 !important; text-decoration: none !important;">here</a>.<br><br>
      
      Upgrade your booking for an extra 6% to receive a 100% refund if you cannot attend for one of the many reasons in our <a href="https://refundable.me/extended/en" target="_blank" style="outline: none !important; transition: 0.2s; color: #2e305b !important; font-weight: 700 !important; text-decoration: none !important;">Terms & Conditions</a> which you accept when you select Refundable Booking(s).
    `;

    this.fullContent = this.sanitizer.bypassSecurityTrustHtml(fullText);
    this.truncatedContent = this.sanitizer.bypassSecurityTrustHtml(fullText.slice(0, 130) + '...');
  }

  toggleContent() {
    this.showFullContent = !this.showFullContent;
  }
}
