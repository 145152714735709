export const boatHolidayItems = [
  {
    id: 0,
    name: 'Boxing Day',
    value: 'boxing-day',
    image: 'assets/images/holidays/boxing-day.jpg',
  },
  {
    id: 1,
    name: 'Vivid Sydney',
    value: 'vivid',
    image: 'assets/images/holidays/vivid-sydney.jpg',
  },
  {
    id: 2,
    name: 'Australia Day',
    value: 'australia day',
    image: 'assets/images/holidays/australia-day.jpg',
  },
  {
    id: 3,
    name: "New Year's Eve",
    value: 'new-years-eve',
    image: 'assets/images/holidays/new-year.jpg',
  },
  {
    id: 4,
    name: 'Christmas Day',
    value: 'christmas-day',
    image: 'assets/images/holidays/christmas-day.jpg',
  },
];
