import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BoatListResponse } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';

@Injectable()
export class BoatsRepositoryService {
  protected readonly http = inject(HttpClient);
  protected readonly apiUrl = environment.boaturl;

  fetchBoats(queryParam = {}, pageNum = 1, pageSize = 12) {
    return this.http.post<BoatListResponse>(`${this.apiUrl}/boatInfo/listWithBriefInfo`, {
      queryParam,
      pageNum,
      pageSize,
    });
  }
}
