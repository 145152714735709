import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  Inject,
  PLATFORM_ID,
  Renderer2,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
import { AppService } from '../app.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { BoatsActions } from './boats/store/boats.actions';
import { Store } from '@ngrx/store';
import FuzzySearch from 'fuzzy-search';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BoatsSelectors } from './boats/store/boats.selectors';
import { animate, style, transition, trigger } from '@angular/animations';
import { BreadcrumbService } from '../shared/services/breadcrumb.service';
import { Breadcrumb } from '../shared/services/breadcrumb.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ transform: 'translateY(-20px)', opacity: 0 }),
        animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class PagesComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  toolbarTypes = [1, 2];
  toolbarTypeOption: number;
  headerTypes = ['default', 'image', 'carousel', 'map', 'video'];
  headerTypeOption: string;
  searchPanelVariants = [1, 2, 3];
  searchPanelVariantOption: number;
  headerFixed = false;
  showBackToTop = false;
  scrolledCount = 0;
  settings: Settings;
  form: FormGroup;
  isGeneralSearchVisible = false;
  isSideNavigationVisible: boolean;
  destroy$ = new Subject<void>();
  boats: any[] = [];
  filteredBoats: any[] = [];
  searcher: FuzzySearch;
  breadcrumbs: Breadcrumb[] = [];

  constructor(
    readonly appSettings: AppSettings,
    readonly router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private readonly formBuilder: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private readonly store: Store,
    private readonly breadcrumbService: BreadcrumbService
  ) {
    this.settings = this.appSettings.settings;
    this.store.dispatch(BoatsActions.loadBoats());

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const snapshot = this.router.routerState.snapshot;
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(snapshot, snapshot.root);
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  ngOnInit() {
    this.toolbarTypeOption = this.settings.toolbar;
    this.headerTypeOption = this.settings.header;
    this.searchPanelVariantOption = this.settings.searchPanelVariant;
    this.form = this.formBuilder.group({
      generalSearch: [''],
    });

    this.store
      .select(BoatsSelectors.boats)
      .pipe(takeUntil(this.destroy$))
      .subscribe(boats => {
        this.boats = boats;
        this.searcher = new FuzzySearch(
          this.boats,
          ['boatName', 'boatType', 'boatFeatures', 'boatActivities', 'priceFrom', 'maxGuest', 'boatDisplayId'],
          { caseSensitive: false }
        );
      });

    this.form
      .get('generalSearch')
      ?.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((searchTerm: string) => {
        this.runFuzzySearch(searchTerm);
      });
  }

  navigateTo(breadcrumb: Breadcrumb) {
    this.router.navigate([breadcrumb.url]);
  }

  runFuzzySearch(searchTerm: string) {
    if (searchTerm) {
      this.filteredBoats = this.searcher.search(searchTerm);
    } else {
      this.filteredBoats = [];
    }
    this.changeDetectorRef.markForCheck();
  }

  navigateToBoat(boatName: string) {
    this.isGeneralSearchVisible = !this.isGeneralSearchVisible;
    this.renderer.setStyle(this.document.body, 'overflow', this.isGeneralSearchVisible ? 'hidden' : 'auto');

    this.router.navigate(['/boats', boatName]);
    this.changeDetectorRef.markForCheck();
  }

  onSearchClick() {
    this.isGeneralSearchVisible = !this.isGeneralSearchVisible;
    this.renderer.setStyle(this.document.body, 'overflow', this.isGeneralSearchVisible ? 'hidden' : 'auto');
    this.changeDetectorRef.markForCheck();
  }

  onSideNavigationClick(fromHeader: boolean, fromMenu: boolean) {
    if (fromHeader) {
      this.isSideNavigationVisible = fromHeader;
    }

    if (fromMenu) {
      this.isSideNavigationVisible = !fromMenu;
    }

    this.changeDetectorRef.markForCheck();
  }

  changeTheme(theme: string) {
    this.settings.theme = theme;
    this.changeDetectorRef.markForCheck();
  }

  chooseToolbarType() {
    this.settings.toolbar = this.toolbarTypeOption;
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }

    this.changeDetectorRef.markForCheck();
  }

  chooseHeaderType() {
    this.settings.header = this.headerTypeOption;
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
    this.router.navigate(['/']);

    this.changeDetectorRef.markForCheck();
  }

  public chooseSearchPanelVariant() {
    this.settings.searchPanelVariant = this.searchPanelVariantOption;
  }

  scrollToTop() {
    const scrollDuration = 200;
    const scrollStep = -window.scrollY / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);

    if (window.innerWidth <= 768) {
      setTimeout(() => {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
