import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  providers: [MenuService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalMenuComponent implements OnInit {
  @Input() menuParentId: number;
  menuItems: Menu[];

  constructor(private readonly menuService: MenuService) {}

  ngOnInit(): void {
    this.menuItems = this.menuService.getHorizontalMenuItems().filter(item => item.parentId === this.menuParentId);
  }
}
