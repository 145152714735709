import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

export interface Breadcrumb {
  readonly label: string;
  readonly url: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
    this.breadcrumbsSubject.next(breadcrumbs);
  }

  getBreadcrumbs(state: RouterStateSnapshot, parent: ActivatedRouteSnapshot): Breadcrumb[] {
    const breadcrumbs: Breadcrumb[] = this.buildBreadcrumbs(parent);
    return [{ label: 'Home', url: '/' }, ...breadcrumbs];
  }

  private buildBreadcrumbs(
    route: ActivatedRouteSnapshot,
    url: string = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const children: ActivatedRouteSnapshot[] = route.children;

    if (route.routeConfig) {
      const routeURL: string = route.routeConfig.path;
      url += `/${routeURL}`;

      const id = route.params['id'];
      const label = route.data && route.data.breadcrumb === ':id' && id ? `${id}` : route.data.breadcrumb || routeURL;

      if (label && !breadcrumbs.some(b => b.label === label)) {
        breadcrumbs.push({ label, url });
      }
    }

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      this.buildBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }
}
